import {
  LandingGreySec,
  LandingGreySecWrap,
} from "../../shared-styled-components";
import LandingFeaturesMobile from "../LandingFeaturesMobile/LandingFeatures.mobile";

const InsuranceMobileSection = (props: any) => {
  return (
    <LandingGreySec marginTop="0px" padding="0 16px 1px 16px">
      <LandingGreySecWrap>
        <LandingFeaturesMobile
          id="featMobile_ref"
          featRef={props?.featRef}
          lug1={props?.lug1}
          lug2={props?.lug2}
          shield={props?.shield}
          shieldRect={props?.shieldRect}
          data={props?.insurance}
          featureImgs={props?.featureImgs}
        />
      </LandingGreySecWrap>
    </LandingGreySec>
  );
};

export default InsuranceMobileSection;
