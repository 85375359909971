import styled from "styled-components";

export const LandingFeatures = styled.div`
  background: #ffffff;
  border-radius: 16px;
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 500px;
  padding: 36px 24px 70px;

  & .greenText {
    color: #0b8041;
    font-weight: 700;
  }
  .lug1,
  .lug2,
  .shield,
  .shieldRect,
  .coins,
  .moneyBag,
  .featureVector,
  .featureVector2 {
    position: absolute;
  }
  & .lug2 {
    width: 46px;
    height: 88px;
    bottom: 169px;
    right: 42px;
    z-index: 2;
  }
  & .lug1 {
    width: 26px;
    height: 96px;
    bottom: 169px;
    right: 4px;
    z-index: 2;
  }
  & .shield {
    width: 56px;
    height: 56px;
    right: 14px;
    bottom: 172px;
    z-index: 5;
    opacity: 0;
    transform: scale(0.5);
  }
  & .shieldRect {
    width: 94px;
    height: 18px;
    right: -2px;
    bottom: 161px;
    position: absolute;
    z-index: 4;
    opacity: 0;
  }
  & .coins {
    width: 67px;
    height: 70px;
    right: 21px;
    bottom: 175px;
    z-index: 3;
    opacity: 0;
  }
  & .moneyBag {
    width: 85px;
    height: 116px;
    right: -7px;
    bottom: 195px;
    z-index: 2;
  }
  & .featureVector {
    width: 134px;
    height: 145px;
    bottom: 148px;
    position: absolute;
    right: 0;
    z-index: 1;
  }
  & .featureVector2 {
    width: 137px;
    height: 89px;
    bottom: 148px;
    position: absolute;
    right: 0;
    z-index: 1;
  }
  & img {
    display: flex;
    margin-right: 10px;
  }
`;

export const LandingFeaturesTitle = styled.h3`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;
  color: #000000;
  margin-bottom: 24px;
`;

export const LandingFeaturesInfo = styled.div`
  font-family: "Open Sans Light", sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #4a4a4a;
  margin-bottom: 24px;
`;

export const LandingFeaturesList = styled.ul`
  font-size: 14px;
  line-height: 30px;
  padding-left: 18px;
  gap: 10px;

  & li {
    position: relative;
    background: linear-gradient(0deg, #000000, #000000),
      linear-gradient(93.66deg, #249083 0%, #249054 100%), #ffffff;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  & li:after {
    content: "";
    position: absolute;
    width: 5px;
    height: 14px;
    left: -18px;
    top: 8px;
    background: #000000;
    border-radius: 14px;
  }
`;

export const PoweredByText = styled.span`
  font-family: "Open Sans Light", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
  margin-right: 5px;
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const LogosImg = styled.img`
  display: flex;
  margin: 0 10px 0 0;
`;
export const DataInfo = styled.span`
  width: 326px;
  font-family: "Open Sans";
  color: #757575;
  margin: auto 0 16px;
  display: inline-block;
`;
export const PoweredByLogo = styled.div`
  margin: 0 16px 0 0;
`;
