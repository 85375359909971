import { useRouter } from "next/router";

import React from "react";
import * as S from "./LandingFeatures.style";
import LandingButton from "../LandingButton/LandingButton";
import useInsuranceSecAnimationMobile from "../../hooks/useInsuranceSecAnimationMobile";
import { pushEvent } from "@tm/utility/analytics";
import { FootNote } from "../LandingCurrDel/LandingCurrDel.style";
import { Image } from "../Image";
import ReactMarkdown from "react-markdown";

const LandingFeaturesMobile = ({ data, featureImgs, id }: any) => {
  useInsuranceSecAnimationMobile();
  const router = useRouter();
  const clickHandler = () => {
    if (data?.buyNowEvent) {
      pushEvent(data.buyNowEvent);
    }
    if (data?.buttonRedirectionUrl) router.push(data.buttonRedirectionUrl);
  };
  return (
    <S.LandingFeatures id={id}>
      <S.LandingFeaturesTitle>{data?.title}</S.LandingFeaturesTitle>
      <S.LandingFeaturesInfo dangerouslySetInnerHTML={{ __html: data?.info }} />
      <S.LandingFeaturesList>
        {data?.featList &&
          data.featList.map((item: any, index: number) => {
            return <li key={index}>{item}</li>;
          })}
      </S.LandingFeaturesList>
      {data?.buttonTxt ? (
        <LandingButton
          margin="auto 0 30px"
          customTitle={data?.buttonTxt}
          customWidth="100%"
          handleClick={clickHandler}
        />
      ) : data.buttonFallbackText ? (
        <ReactMarkdown>{data.buttonFallbackText}</ReactMarkdown>
      ) : (
        <>
          <br />
          Coming soon
          <br />
          <br />
        </>
      )}

      {data.dataInfo && <S.DataInfo>{data.dataInfo}</S.DataInfo>}

      <FootNote>
        <S.PoweredByText>{data?.poweredBy?.title}</S.PoweredByText>
        {data?.poweredBy?.logos?.map((item: any, index: number) => {
          return (
            <S.PoweredByLogo>
              <Image {...item} key={index} />
            </S.PoweredByLogo>
          );
        })}
      </FootNote>
      {data?.aniImg == "img1" && (
        <React.Fragment>
          <Image
            id="lug1Mobile_ref"
            width="26"
            height="96"
            className="lug1"
            {...data?.cardImgs?.[0]}
            key={0}
          />
          <Image
            id="lug2Mobile_ref"
            width="46"
            height="88"
            className="lug2"
            {...data?.cardImgs?.[1]}
            key={1}
          />
          <Image
            id="shieldMobile_ref"
            width="56"
            height="56"
            className="shield"
            {...data?.cardImgs?.[2]}
            key={2}
          />
          <Image
            id="shieldRectMobile_ref"
            alt=""
            width="94"
            height="18"
            className="shieldRect"
            src="../../images/shieldRect.svg"
            {...data?.cardImgs?.[3]}
            key={3}
          />
        </React.Fragment>
      )}
      {data?.aniImg == "img2" && (
        <React.Fragment>
          <Image
            id="coinsMobile_ref"
            width="67"
            height="70"
            className="coins"
            {...data?.cardImgs?.[2]}
            key={0}
          />
          <Image
            id="moneyBagMobile_ref"
            width="85"
            height="116"
            className="moneyBag"
            {...data?.cardImgs?.[3]}
            key={1}
          />
        </React.Fragment>
      )}
      {data?.aniImg == "img1" && (
        <Image
          width="134"
          height="145"
          className="featureVector2"
          {...featureImgs?.[0]}
        />
      )}
      {data?.aniImg == "img2" && (
        <Image
          width="137"
          height="89"
          className="featureVector"
          {...featureImgs?.[1]}
        />
      )}
    </S.LandingFeatures>
  );
};

export default LandingFeaturesMobile;
