import { useEffect } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";

const useInsuranceSecAnimationMobile = () => {
  const ANIMATION_TYPE = "ease-in-out";
  gsap.registerPlugin(ScrollTrigger);
  useEffect(() => {
    setTimeout(() => {
      const rootContainerElement = document.getElementById("root_container");
      const shieldMobileElement = document.getElementById("shieldMobile_ref");
      const shieldRectMobileElement = document.getElementById(
        "shieldRectMobile_ref"
      );
      const coinsMobileElement = document.getElementById("coinsMobile_ref");
      const moneyBagMobileElement =
        document.getElementById("moneyBagMobile_ref");
      const featMobileElement = document.getElementById("featMobile_ref");
      const feat2MobileElement = document.getElementById("featMobile2_ref");

      gsap.to(moneyBagMobileElement, {
        scrollTrigger: {
          scroller: rootContainerElement,
          trigger: feat2MobileElement,
          start: "top center",
          toggleActions: "restart none none reverse",
        },
        x: -44,
        ease: ANIMATION_TYPE,
        duration: 0.6,
      });
      gsap.to(coinsMobileElement, {
        scrollTrigger: {
          scroller: rootContainerElement,
          trigger: feat2MobileElement,
          start: "top center",
          toggleActions: "restart none none reverse",
        },
        opacity: 1,
        delay: 0.4,
        ease: ANIMATION_TYPE,
        duration: 0.6,
      });
      gsap.to(shieldMobileElement, {
        scrollTrigger: {
          scroller: rootContainerElement,
          trigger: featMobileElement,
          start: "top center",
          toggleActions: "restart none none reverse",
        },
        opacity: 1,
        scale: 1,
        delay: 0.3,
        ease: ANIMATION_TYPE,
        duration: 0.6,
      });
      gsap.to(shieldRectMobileElement, {
        scrollTrigger: {
          scroller: rootContainerElement,
          trigger: featMobileElement,
          start: "top center",
          toggleActions: "restart none none reverse",
        },
        y: -30,
        opacity: 1,
        ease: ANIMATION_TYPE,
        delay: 0.3,
        duration: 0.6,
      });
    }, 300);
  }, []);
};
export default useInsuranceSecAnimationMobile;
